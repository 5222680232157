import React from "react";

const Arrow: React.FC<{ click: () => void }> = ({ click }) => {
  return (
    <svg
      className="arrow"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // ref={ref}
      onClick={click}
    >
      <path d="M11.29 8.45999L5.64001 14.12C5.54628 14.213 5.47188 14.3236 5.42111 14.4454C5.37035 14.5673 5.34421 14.698 5.34421 14.83C5.34421 14.962 5.37035 15.0927 5.42111 15.2146C5.47188 15.3364 5.54628 15.447 5.64001 15.54C5.82737 15.7262 6.08082 15.8308 6.34501 15.8308C6.60919 15.8308 6.86264 15.7262 7.05001 15.54L12.05 10.59L17 15.54C17.1874 15.7262 17.4408 15.8308 17.705 15.8308C17.9692 15.8308 18.2226 15.7262 18.41 15.54C18.5045 15.4474 18.5797 15.3369 18.6312 15.2151C18.6827 15.0932 18.7095 14.9623 18.71 14.83C18.7095 14.6977 18.6827 14.5668 18.6312 14.4449C18.5797 14.323 18.5045 14.2126 18.41 14.12L12.76 8.45999C12.6664 8.35848 12.5527 8.27747 12.4262 8.22207C12.2997 8.16666 12.1631 8.13805 12.025 8.13805C11.8869 8.13805 11.7503 8.16666 11.6238 8.22207C11.4973 8.27747 11.3836 8.35848 11.29 8.45999V8.45999Z" />
    </svg>
  );
};

export default Arrow;
